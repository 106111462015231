import * as S from './styles'
import { useMemberContext } from '../../contexts/Members/MembersContext'

const MembersList: React.FC = () => {
  const { members, fetchMembers, removeMember } = useMemberContext() // Adicione o removeMember do contexto

  const handleDeleteMember = async (
    e: React.FormEvent<HTMLButtonElement>,
    id: string
  ) => {
    e.preventDefault()
    await removeMember(id)
    await fetchMembers()
  }

  return (
    <S.MembersListContainer>
      <S.MembersTitle>Lista de Alunos</S.MembersTitle>
      <div>
        <S.Table>
          <thead>
            <tr>
              <th>Nome</th>
              <th colSpan={2}>Telefone</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member.id}>
                <td>{member.name}</td>
                <td>{member.phone}</td>
                <td>
                  <S.Button onClick={(e) => handleDeleteMember(e, member.id)}>
                    x
                  </S.Button>
                </td>
              </tr>
            ))}
          </tbody>
        </S.Table>
      </div>
    </S.MembersListContainer>
  )
}

export default MembersList
